import React from 'react';

import ExternalLink from '@hub-fe/common/ExternalLink';
import SiteLogo from '@hub-fe/common/graphics/SiteLogo';
import { siteName } from '@hub-fe/common/siteInfo.variants';
import * as urls from '@hub-fe/common/urls';
import { DamlHubHelmet } from '@hub-fe/landing/nav';

const MaintenancePage: React.FC = () => {
    return (
        <div className="roomy gatsby-maintenance default">
            <DamlHubHelmet title="Maintenance" />
            <div className="top">
                <SiteLogo size="large" />
            </div>
            <h1>{siteName} is currently undergoing maintenance </h1>
            <p>
                {' '}
                for more information about {siteName} check out our{' '}
                {<ExternalLink to={urls.docs}> docs </ExternalLink>}{' '}
            </p>
        </div>
    );
};

// Needed for Gatsby
// ts-unused-exports:disable-next-line
export default MaintenancePage;
